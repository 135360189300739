import React, { useState, useEffect, useContext } from 'react';

import { User } from "../../../models/User";
import styles from '../../../styles/TaskItem.module.css';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { Card, CardContent, Button, Stack, Alert } from '@mui/material';
import { useTranslation } from "react-i18next";
import {useNavigate} from 'react-router-dom';
import { PlanDefinition } from '../../../models/PlanDefinition';
import { PathwayDetails } from './PathwayDetails';
import { CarePlan } from '../../../models/CarePlan';
import { timeConverter } from '../../../service/timeConverter';
import { CarePlanService } from '../../../service/carePlanService';
import { fetchData, sendData } from '../../../service/dataService';
import { useForm } from 'react-hook-form';

import { useAuthToken } from '../../../components/Auth0/Auth0ProviderWithToken';
import { PatientListItem } from '../../../models/PatientListItem';
import { ScheduledTiming } from '../../../models/CarePlan_activity';

import { getAnalytics, logEvent } from "firebase/analytics";
import { useFirebase } from '../../Firebase/FirebaseContext';
import PatientsContext from '../../../contexts/PatientContext';

type PatientProps = {
    patient: PatientListItem,
    refresh: any,
    carePlan: CarePlan
}

export function AddPatientPathway({refresh, carePlan, patient} : PatientProps) {

    const { i18n, t} = useTranslation();
    const navigate = useNavigate();
    const defaultValues = {pathway: undefined};
    const methods = useForm({defaultValues});
    const { ToString, ToDateTime } = timeConverter();
    const { app, analytics } = useFirebase();

    const [ pathways, setPathways ] = useState<PlanDefinition[]>([]);

    const token  = useAuthToken();

    useEffect(() => {
        const fetchPathways = async () => {
            try {
                const responseData = await fetchData('crud','PlanDefinition', `{}`);
                setPathways(responseData);
            } catch (error) {
                console.error('Error fetching data: ', error)
            }
        }
        if(token){
            fetchPathways();
        }
    }, [token]);

    const { constructBasic } = CarePlanService();

    const [selectedPathway, setPathway] = React.useState<string>("default");

    const handleChange = (event: SelectChangeEvent<typeof selectedPathway>) => {
        let pathwayName = event.target.value as string;
        constructCarePlan(carePlan,pathwayName);
        setPathway(pathwayName)
    };

    function findPathway(name: string) : (PlanDefinition | undefined)  {
        return pathways.find((pwName) => pwName.name === name);
    }

    function constructCarePlan(carePlan: CarePlan, pathwayName: string) {
        let pathway = findPathway(pathwayName);
        constructBasic(carePlan,pathway!,patient?.id || "");
    }

    async function saveCarePlan() {
        logEvent(analytics, "clinician_add_careplan")
        await sendData("crud","CarePlan",carePlan);
        refresh();
        navigateToViewPage();
    }

    const navigateToViewPage = () => {
        try {
          navigate(-1);
        }catch(error){
            console.error('Failed to navigate to view page', error)
        }
      }

      const clearErrors = () => {
        methods.clearErrors();
      }
      
      const onSubmit = () => {
        let hasError = false;
        carePlan.activity.forEach(act => {
          let freq = act.detail.scheduledTiming;
          if (freq.code !== undefined && freq.code?.text === ScheduledTiming.continuous) {
            return;
          }
          if (freq.repeat === undefined || freq.repeat.period === undefined || freq.repeat.period === 0 || freq.repeat.periodUnit === undefined) {
            methods.setError("pathway",{type:"custom", message:"Pathway frequency must be defined"});
            hasError = true;
          }
        })
        if (!hasError) saveCarePlan();
      }

    return (
        <div>
            
            <div className={styles.tasks}>
                    <Card>
                        <CardContent>
                        <h3>{t("pathway.select-pathway-assign")}</h3>
                            <InputLabel id="pathway-select-label"></InputLabel>
                                <Select
                                labelId="pathway-select-label"
                                id="pathway-select"
                                value={selectedPathway}
                                onChange={handleChange}
                                notched={false}
                                placeholder={"placeholder"}
                                input={<OutlinedInput id="pathway-select-input" label="input" />}
                                >
                                    <MenuItem disabled={true} key="" value={"default"}>
                                        -- {t("pathway.select-pathway")} --
                                    </MenuItem>
                                {pathways.map((pathway: PlanDefinition) => (
                                    <MenuItem
                                    key={pathway?.name}
                                    value={pathway?.name}
                                    >
                                    {pathway?.title}
                                    </MenuItem>
                                ))}
                                </Select>
                                <br/>
                                { (selectedPathway !== "default") ?  
                                <Card>
                                    <CardContent>
                                        <PathwayDetails pathway={findPathway(selectedPathway)!} carePlan={carePlan} refresh={clearErrors} />
                                    </CardContent>
                                </Card>
                                : <Card></Card> }
                                <br />
                                <form onSubmit={methods.handleSubmit(onSubmit)}>
                                { methods.formState.errors.pathway && <Alert severity="error">{t('pathway.errors.frequency-not-set')}</Alert>}
                                <Stack direction="row" spacing={2}>
                                    <Button variant="outlined" onClick={navigateToViewPage}>{t("command.cancel")}</Button>
                                    <Button variant="contained" type="submit">{t("command.save")}</Button>
                                </Stack>
                                </form>
                        </CardContent>
                    </Card>
                <br />
            </div>
        </div>

    );
}
