import { Subject } from "./Subject";
import { Period } from "./Period";
import { CarePlan_activity } from './CarePlan_activity';

export class CarePlan {
  _id?: Realm.BSON.ObjectId;
  resourceType?: string;
  instantiatesCanonical!: string[]
  status?: string;
  title?: string;
  subject!: Subject;
  period!: Period;
  created!: string;
  activity!: Array<CarePlan_activity>;
};