import { User } from "../../../models/User";
import styles from '../../../styles/TaskItem.module.css';

import { Card, CardContent, Button, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import { useTranslation } from "react-i18next";
import { Add } from '@mui/icons-material';

import React, { useState, useEffect, useContext} from 'react';
import { AddPatientPathway } from "./AddPatientPathway";
import { CarePlanRow } from "./CarePlanRow";

import { CarePlan } from '../../../models/CarePlan';

import { CarePlanService } from "../../../service/carePlanService";
import { useNavigate } from "react-router-dom";
import { fetchData } from '../../../service/dataService';

import { useAuthToken } from '../../../components/Auth0/Auth0ProviderWithToken';
import { PatientListItem } from "../../../models/PatientListItem";
import PatientsContext from "../../../contexts/PatientContext";

import { fhirBaseUrl } from "../../../constants/fhirBaseUrl";

type PatientProps = {
    patientId: string
}

enum patientPathwayView{
    Default,
    AddPathway
}

export function PatientPathway({patientId} : PatientProps) {
    const { i18n, t} = useTranslation();
    const navigate = useNavigate();
    const { patient, setPatient } = useContext(PatientsContext);

    const [carePlans, setCarePlans] = useState([]);

    const token  = useAuthToken();

    useEffect(() => {
        const fetchCarePlans = async () => {
            try {
                const responseData = await fetchData('crud','CarePlan', `{"subject":{"reference":"${fhirBaseUrl}/Patient/${patient?.id}"}}`);
                setCarePlans(responseData);
            } catch (error) {
                console.error('Error fetching data: ', error)
            }
        }
        if(token){
            fetchCarePlans();
        }
    }, [token]);

    const PatientPathwayDefault = ({patientId}:PatientProps ) => {

        const { i18n, t} = useTranslation();
    
        const handleChange = (e: any) => {
            navigate('/pathway/add', { state: {patient : patient, carePlan : new CarePlan}})
          };
    
        return (
            <div>
                <div className={styles.tasks}>
                    <Card>
                        <CardContent>
                        <div><strong>{patient?.firstName} {patient?.lastName} {t("pathway.pathway")}</strong></div>
                        { (carePlans.length !== 0) ?
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t('pathway.title')}</TableCell>
                                        <TableCell>{t('pathway.start-date')}</TableCell>
                                        <TableCell>{t('common.actions')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                        {carePlans.map((carePlan:CarePlan) => (
                            <CarePlanRow carePlan={carePlan} patientId={patientId}></CarePlanRow>
                        )
                        )}
                                </TableBody>            
                            </Table>
                        :
                            <div>{t("pathway.patient-not-assigned")}</div>
                        }
                        <br />
                        <Button variant="contained" onClick={handleChange} startIcon={<Add />}>{t("pathway.add-pathway")}</Button>
                        </CardContent>
                    </Card>
                <br />
                </div>
            </div>
    
        );
    }

        return <PatientPathwayDefault patientId={patientId} />
}
