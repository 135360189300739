import { CarePlan } from '../../../models/CarePlan';
import { TableRow, TableCell, Stack } from '@mui/material';
import { useTranslation } from "react-i18next";
import { timeConverter } from '../../../service/timeConverter';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import PatientsContext from '../../../contexts/PatientContext';
import React from 'react';


type CarePlanProps = {
    carePlan : CarePlan,
    patientId: string
}

export function CarePlanRow(response : CarePlanProps) { 

    const { i18n, t} = useTranslation();
    const { ToString, ToDateTime } = timeConverter();
    const { patient, setPatient } = useContext(PatientsContext);
    const navigate = useNavigate();

    const navigateToEditPathway = () => {
        try {
            navigate('/pathway/edit', { state: {carePlan: response.carePlan, patient: patient}});
          }catch(error){
              console.error('Failed to navigate to view page', error)
          }
    }

    return (
        <TableRow>
            <TableCell>{response.carePlan.title}</TableCell>
            <TableCell>{ToDateTime(response.carePlan.period.start).toLocaleString()}</TableCell>
            <TableCell><button onClick={navigateToEditPathway}>{t('command.edit')}</button></TableCell>
        </TableRow>    
    );
}
