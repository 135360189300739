
import { Paper, CardContent, Grid } from '@mui/material';
import { useTranslation } from "react-i18next";
import { styled } from '@mui/material/styles';
import { RAG } from '../../constants/RAG';
import { answerQuestionnaireResponseRAG, getQuestionnaireResponseRAG } from '../../helpers/questionnaireResponseHelper';
import { BorderColor } from '@mui/icons-material';
import { RAGRaterAnswerChip } from './RAGRaterAnswerChip';
import { timeConverter } from '../../service/timeConverter';


type QuestionnaireTaskProps = {
    question: fhir4b.QuestionnaireResponseItem
}


export function QuestionnaireAnswerCard({question} : QuestionnaireTaskProps) {

    const { i18n, t} = useTranslation();
    const { ToDateTime } = timeConverter();

    function findPopulatedAnswer(item: fhir4b.QuestionnaireResponseItem) {
        let combinedAnswerList: String[] = [];

        item?.answer?.forEach(x  => {
            let formattedAnswer = extractValue(x);
            if (formattedAnswer) {
                combinedAnswerList.push(formattedAnswer.toString())
            }
        });
        
        return combinedAnswerList.join(', ');
    }

    function extractValue(answer: fhir4b.QuestionnaireResponseItemAnswer){
        if (!answer) return "Unknown answer";

        if (answer?.valueCoding?.display) return answer.valueCoding.display.trim();
        if (answer.valueInteger != undefined) return answer.valueInteger;
        if (answer.valueDecimal != undefined) return answer.valueDecimal;
        if (answer?.valueString) return answer.valueString.trim();
        if (answer?.valueDate) return ToDateTime(answer.valueDate).toLocaleString();
        if (answer.valueBoolean != undefined) return answer.valueBoolean ? "Yes" : "No";
        if (answer.valueQuantity != undefined) return `${answer.valueQuantity.value} ${answer.valueQuantity.unit}`;
        if (answer.valueReference != undefined) return null;


        return "Unknown answer"
    }

    function calculateAnswerColor(item: fhir4b.QuestionnaireResponseItem, theme: any){
        var answerRAG = answerQuestionnaireResponseRAG(item);

        switch(answerRAG){
            case RAG.Red:
                return {
                    border: '3px solid',
                    borderColor: '#C70000',
                    backgroundColor: '#FBE6E6',
                    ...theme.typography.body2,
                    padding: theme.spacing(2),
                    textAlign: 'left',
                    color: theme.palette.text.secondary,
                  };
            case RAG.Amber:
                return {
                    border: '2px solid',
                    borderColor: '#FFCC00',
                    ...theme.typography.body2,
                    padding: theme.spacing(2),
                    textAlign: 'left',
                    color: theme.palette.text.secondary,
                  };
            case RAG.Green: 
                return {
                    ...theme.typography.body2,
                    padding: theme.spacing(2),
                    textAlign: 'left',
                    color: theme.palette.text.secondary,
                  };
            case RAG.Unscored:
                return '';
        }
    }

    var answerRAG = answerQuestionnaireResponseRAG(question);

    const Card = styled(Paper)(({ theme }) => (
        calculateAnswerColor(question, theme)
    ));

      return (
        <div>   
            <Card className='redCard answerCard' > 
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={11}>
                            <span className='title'>{t("task.question")}:</span>
                            <div><h5>{question.text}</h5></div>
                            <br />
                            <span className='title'>{t("task.answer")}:</span>
                            <div><h5>{findPopulatedAnswer(question)}</h5></div>
                        </Grid>
                        <Grid item xs={1}>
                            <RAGRaterAnswerChip rag={answerQuestionnaireResponseRAG(question)} />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </div>
    );
}
