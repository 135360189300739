
import { PlanDefinition } from '../models/PlanDefinition';
import { CarePlan } from '../models/CarePlan';
import { Period } from '../models/Period';
import { CarePlan_activity, CarePlan_activityDetail, ScheduledTiming, ScheduledTiming_repeat } from '../models/CarePlan_activity';
import { Subject } from '../models/Subject';
import { DateTime } from 'luxon';
import { timeConverter } from './timeConverter';

import { fhirBaseUrl } from '../constants/fhirBaseUrl';

export function CarePlanService() {

    const {ToString, ToDateTime } = timeConverter();

    const constructBasic = (carePlan: CarePlan, pathway: PlanDefinition, userId: string) => {
        carePlan.instantiatesCanonical = [pathway?.url!];
        carePlan.period = new Period();
        carePlan.period.start = ToString(DateTime.now());
        carePlan.resourceType = "CarePlan";
        carePlan.status = "active";
        carePlan.created = ToString(DateTime.now());
        carePlan.subject = new Subject();
        carePlan.subject.reference = `${fhirBaseUrl}/Patient/${userId}`;
        carePlan.title = pathway?.title;
    
        carePlan.activity = new Array<CarePlan_activity>();
        pathway?.action.forEach(act => {
            let activity = new CarePlan_activity();
            activity.detail = new CarePlan_activityDetail();
            activity.detail.instantiatesCanonical = [act.definitionCanonical!];
            activity.detail.scheduledTiming = new ScheduledTiming();
            activity.detail.scheduledTiming.repeat = new ScheduledTiming_repeat();
    
            carePlan.activity.push(activity);
        })
      }

    return {
        constructBasic
    }
}