import styles from '../../../styles/TaskPage.module.css';
import { useLocation } from 'react-router-dom';
import { EditPathway } from '../../../components/Patient/Pathway/EditPathway';

export function EditPathwayPage() {
  const location = useLocation();
  const patient = location.state.patient;
  const carePlan = location.state.carePlan;
  const refresh = () => {}

  return (
    <div className={styles.container}>
       <EditPathway patient={patient} carePlan={carePlan} />
    </div>
  );
}