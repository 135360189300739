import { Button, Input, Stack, TableRow, TableCell, Modal, Box, Typography, MenuItem, Alert, Checkbox, FormControlLabel, FormControl } from '@mui/material';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import { useTranslation } from "react-i18next";
import * as React from 'react';
import { PlanDefinition_action } from '../../../models/PlanDefinition_action';
import { CarePlan } from '../../../models/CarePlan';
import { CarePlan_activity, ScheduledTiming, ScheduledTiming_repeat } from '../../../models/CarePlan_activity';
import { Controller, useForm } from 'react-hook-form';
import { CodeableConcept } from '../../../models/CodeableConcept';

type PathwayActivityProps = {
    action : PlanDefinition_action,
    carePlan : CarePlan,
    refresh: any
}

export function PathwayActivityRow(response : PathwayActivityProps) {

    const activity = findActivity();
    const defaultValues = {
        frequencyNumber:activity.detail?.scheduledTiming?.repeat?.period ?? 1,
        frequencyUnit:activity.detail?.scheduledTiming?.repeat?.periodUnit ?? "d",
        continuous:activity.detail?.scheduledTiming?.code?.text == ScheduledTiming.continuous ?? false};
    const methods = useForm({defaultValues});

    const { i18n, t} = useTranslation();

    const [ frequencyModalOpen, setFrequencyModalOpen ] = React.useState<boolean>(false);

    function findActivity() : CarePlan_activity {
        return response.carePlan!.activity.find(x => x.detail.instantiatesCanonical[0] == response.action.definitionCanonical)!;
    }

    function displayFrequency() : string | null {

        let frequency = activity?.detail.scheduledTiming.repeat;
        if (frequency?.period === undefined) {
            let continuous  = activity?.detail.scheduledTiming.code;
            if (continuous?.text === ScheduledTiming.continuous) {
                return t('frequency.continual');
            }
            return null;
        }
        return t('frequency.every')+" "+frequency?.period+" "+decipherUnit(frequency?.periodUnit);
    }

    function setFrequency(frequencyNumber:number,frequencyUnit:string,continuous:boolean)  {
        let scheduledTiming = activity?.detail.scheduledTiming;
        if (continuous) {
            scheduledTiming!.repeat = undefined;
            scheduledTiming!.code = new CodeableConcept();
            scheduledTiming!.code.text = ScheduledTiming.continuous;
        } else {
            scheduledTiming!.code = undefined;
            scheduledTiming!.repeat = new ScheduledTiming_repeat();
            scheduledTiming!.repeat.period = frequencyNumber;
            scheduledTiming!.repeat.periodUnit = frequencyUnit;
        }
    
        closeSetFrequency();
    }

    const onSubmit = (data:any) => {
        setFrequency(methods.getValues().frequencyNumber,methods.getValues().frequencyUnit, methods.getValues().continuous);
    
    }

    const openSetFrequency = () => { 
        response.refresh();
        methods.setValue("frequencyNumber",activity.detail.scheduledTiming?.repeat?.period ?? 1);
        methods.setValue("frequencyUnit",activity.detail.scheduledTiming?.repeat?.periodUnit ?? "d");
        methods.setValue("continuous",activity.detail.scheduledTiming?.code?.text == ScheduledTiming.continuous ?? false);
        setFrequencyModalOpen(true); 
    }

    const closeSetFrequency = () => { setFrequencyModalOpen(false); }

    function decipherUnit(code: string | undefined) : string {
        if (code == "d") return t("frequency.days");
        if (code == "h") return t("frequency.hours");
        if (code == "wk") return t("frequency.weeks");
        return "unknown";
    }

    const modalStyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };

    return (
        <>
        <TableRow>
            <TableCell>{response.action.title}</TableCell>
            <TableCell>{t('pathway.questionnaire')}</TableCell>
            <TableCell>{displayFrequency() ?? <Typography color="red">{t('frequency.unset')}</Typography>}</TableCell>
            <TableCell><Button onClick={() => openSetFrequency()}>{t('pathway.set-frequency')}</Button></TableCell>
        </TableRow>
            <Modal

            open={frequencyModalOpen}
            onClose={closeSetFrequency}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Box sx={modalStyle}>
                        <Typography variant="h5" gutterBottom>{t('pathway.frequency.edit-title')}</Typography>

                        <Stack direction="row">
                        <FormControlLabel label={t('pathway.frequency.continual-label')}
                                control={
                                <Controller name='continuous' control={methods.control}
                                render={({ field: { onChange, onBlur, value, ref }, fieldState: {error} }) => (

                                    <Checkbox id="continuous-marker"
                                        checked={value} ref={ref}
                                        onChange={onChange}
                                    />
                                )}></Controller>}></FormControlLabel>
                        </Stack>
                        <Stack><label>{t('common.or')}</label></Stack>
                        <Stack><Typography>{t('pathway.frequency.title')}</Typography></Stack>
                        <Stack direction="row">
                            <Input
                            id="frequency-number"
                            disabled={methods.watch("continuous")}
                            {...methods.register('frequencyNumber', {
                                valueAsNumber: true,
                                validate: {
                                    required: value => (value !== undefined || methods.getValues('continuous')) || t('pathway.errors.frequency-must-be-set'),
                                    min: value => (value > 0 || methods.getValues('continuous')) || t('pathway.errors.frequency-must-be-gt-zero'),
                                    max: value => (value < 500 || methods.getValues('continuous')) || t('pathway.errors.frequency-must-be-lt-500'),
                                    isNumber: value => (!Number.isNaN(value) || methods.getValues('continuous'))|| t('pathway.errors.frequency-must-be-numeric'),
                                    isInteger: value => (Number.isInteger(value) || methods.getValues('continuous')) || t('pathway.errors.frequency-must-be-numeric')
                                }
                                })}
                            />
                            <Select
                                id="frequency-unit"
                                disabled={methods.watch("continuous")}
                                defaultValue={methods.getValues().frequencyUnit}
                                {...methods.register('frequencyUnit')}
                                    >
                                        <MenuItem value="h">{t('frequency.hours')}</MenuItem>
                                        <MenuItem value="d">{t('frequency.days')}</MenuItem>    
                                        <MenuItem value="wk">{t('frequency.weeks')}</MenuItem>
                            </Select>                        
                        </Stack>
                        <Typography variant="caption">{t('pathway.frequency.often-message')}</Typography>
                        <br />
                        <p>
                            {methods.formState.errors.frequencyNumber && <Alert severity="error">{methods.formState.errors.frequencyNumber.message}</Alert>}
                        </p>
                        <Stack direction="row">
                            <Button id="cancel" onClick={closeSetFrequency}>{t('command.cancel')}</Button>
                            <Button id="confirm" type="submit">{t('command.confirm')}</Button>
                        </Stack>
                    </Box>
                </form>
        </Modal>    
        </>    
    )
}