import { fetchData } from "../../../service/dataService";
import { useState, useEffect, useContext } from 'react';
import React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RAGRaterChip } from "../../Questionnaire/RAGRaterChip";
import styles from '../../../styles/TaskPage.module.css';
import { DateTime } from 'luxon';
import { timeConverter } from '../../../service/timeConverter';
import { useAuthToken } from '../../Auth0/Auth0ProviderWithToken';
import { useFirebase } from '../../Firebase/FirebaseContext';
import { 
  extractReviewedDate, 
  extractReviewedName,
  extractCompletedDate, 
  getQuestionnaireResponseRAG, 
  getQuestionnaireResponseScore, 
  getPatientId,
  getQuestionnaireTitle,
} from '../../../helpers/questionnaireResponseHelper';
import { getAnalytics, logEvent } from "firebase/analytics";
import PatientsContext from "../../../contexts/PatientContext";
// Define your custom styles
const customStyles = {
  '& .MuiDataGrid-row:hover': {
    backgroundColor: 'rgba(242,224,251,0.3)', // Change this color to your desired hover color
    '& .MuiDataGrid-cell': {
      fontWeight: '600', // Make text bold on hover
    },
  },
};

interface ActivityItem {
    id: string;
    questionnaireTitle: string;
    authoredDate: string;
    completedDate: string;
    reviewedBy: string;
    reviewedByName: string;
    score: number;
    questionnaireResponse: fhir4b.QuestionnaireResponse;
  }

interface ActivtyListProps {
    patientId: string
}

export default function ActivityList({patientId}:ActivtyListProps) {
    const { t } = useTranslation();
    const [data, setData] = useState<ActivityItem[]>([]);
    const navigate = useNavigate();
    const token  = useAuthToken();
    const { app, analytics } = useFirebase();
    const { ToDateTime } = timeConverter();
    const {patient, setPatient, message, setMessage} = useContext(PatientsContext);
  
    const navigateToViewPage = (questionnaireResponse: fhir4b.QuestionnaireResponse) => {
      try {
        navigate('/questionnaire', { state: { questionnaireResponse: questionnaireResponse } });
      } catch (error) {
        console.error('Failed to navigate to view page', error);
      }
    };
  
    useEffect(() => {
      const fetchQuestionnaireResponses = async () => {
        try {
  
            const query = `{"$or":[{"subject.id":"${patient!.fhirId}"},{"subject.reference":"${patient!.fhirId}"}]}`
  
          const responseData = await fetchData('crud','APIQuestionnaireResponse', query );
  
          let mappedData: ActivityItem[] = responseData.map((item: fhir4b.QuestionnaireResponse) => ({
              id: item._id,
              patientId: getPatientId(item),
              questionnaireTitle: getQuestionnaireTitle(item),
              authoredDate: extractCompletedDate(item),
              reviewedDate: extractReviewedDate(item),
              reviewedByName: extractReviewedName(item,t("questionnaireResponse.historic")),
              questionnaireResponse: item,
              score: getQuestionnaireResponseScore(item as fhir4b.QuestionnaireResponse )          
          }));
  
          mappedData.sort((a, b) => ToDateTime(a.authoredDate).toSeconds() - ToDateTime(b.authoredDate).toSeconds() )
  
          setData(mappedData);
        } catch (error) {
            console.error('Error fetching data: ', error)
        }
      };
  
        if(token){
          fetchQuestionnaireResponses();
        }
    }, [token]);
  
      const handleRowClick = (params: any) => {
       logEvent(analytics, "clinician_view_questionnaireResponse")
       navigateToViewPage(params.row.questionnaireResponse as fhir4b.QuestionnaireResponse)
    };
  
    const columns: GridColDef[] = [
      {
        field: 'score',
        headerName: t('task.questionnaire-response-rag'),
        renderCell: (params) => (
          <div>
            <RAGRaterChip rag={getQuestionnaireResponseRAG(params.row.questionnaireResponse as fhir4b.QuestionnaireResponse)} />
          </div>
        ),
        flex: 1,
        hideable: false,
      },
      { field: 'questionnaireTitle', headerName: t('task.questionnaire-name'), flex: 1 },
      {
        field: 'authoredDate',
        headerName: t('task.completed-date'),
        flex: 1,
        hideable: false,
        valueFormatter: (params) => {
          if(!params) return ''
          var luxonDate = ToDateTime(params);
          return luxonDate.toLocaleString(DateTime.DATETIME_MED);
        }
      },
      { field: 'pathwayName', headerName: t('Pathway'), flex: 1 },
      {
        field: 'reviewedDate',
        headerName: t('task.reviewed-date'),
        flex: 1,
        hideable: false
      },
      {
        field: 'reviewedByName',
        headerName: t('task.reviewed-name'),
        flex: 1,
        hideable: false
      },
      {
  
        field: 'viewButton',
        headerName: '',
        renderCell: () => (
          <div className={styles.viewButtonWrapper}>
            <button className="viewButton">{t("command.view")}</button>
  
          </div>
        ),
        width: 60,
        hideable: false,
      },
    ];
  
    return (
        <div>
          <h5>{t("patient.activity-header",{firstName:patient?.firstName, lastName:patient?.lastName})}</h5>
          <DataGrid
            rows={data}
            columns={columns}
            initialState={{
              sorting: {
                sortModel: [{field:'authoredDate', sort: 'desc'}]
              },
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[5, 10, 20]}
            getRowId={(row) => row.id}
            onRowClick={handleRowClick}
            sx={customStyles} // Apply custom styles using the sx prop
          />
        </div>
    );  
}