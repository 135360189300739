import styles from '../../styles/TaskItem.module.css';

import Button from '@mui/material/Button';
import {useNavigate, Link } from 'react-router-dom';

import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";

import { extractReviewedDate } from '../../helpers/questionnaireResponseHelper'
import { updateData } from "../../service/dataService";

import { spiritFhirExtensions } from '../../constants/spiritFhirExtensions';
import { extractCompletedDate, getQuestionnaireResponseRAG, getPatientId, getQuestionnaireTitle, extractCompletedDateFormatted } from '../../helpers/questionnaireResponseHelper';

import moment from 'moment';
import { RAGRaterChip } from '../../components/Questionnaire/RAGRaterChip';
import { QuestionnaireAnswerCard } from './QuestionnaireAnswerCard';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getAnalytics, logEvent } from "firebase/analytics";
import { useFirebase } from '../Firebase/FirebaseContext';
import { useAuth0 } from '@auth0/auth0-react';


type QuestionnaireTaskProps = {
    questionnaireResponse: fhir4b.QuestionnaireResponse
}

export function QuestionnaireView({questionnaireResponse} : QuestionnaireTaskProps) {

    const navigate = useNavigate();
    const { i18n, t} = useTranslation();
    const { app, analytics } = useFirebase();
    const { user } = useAuth0();


    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.text.secondary,
      }));

    function back() {
        navigate(-1);
    }

    function reviewComplete(questionnaireResponse: fhir4b.QuestionnaireResponse) {
        logEvent(analytics, 'clinician_mark_questionnaire_as_reviewed');
        document.getElementById('root')?.children[0]?.scrollTo(0,0)
        updateData("crud","APIQuestionnaireResponse", String(questionnaireResponse._id), setQuestionnaireResponseReviewed(questionnaireResponse))
        navigate('/questionnaire', { state: {questionnaireResponse : questionnaireResponse}});
    }

    function setQuestionnaireResponseReviewed(questionnaireResponse: fhir4b.QuestionnaireResponse) : fhir4b.QuestionnaireResponse {
        var extensions = questionnaireResponse?.extension || [];

        extensions = extensions.filter(x => x.url !== spiritFhirExtensions.reviewedDate);

        extensions.push({
            url: spiritFhirExtensions.reviewedDate,
            valueDateTime: moment().toISOString()
        });

        extensions = extensions.filter(x => x.url !== spiritFhirExtensions.reviewedByName);

        extensions.push({
            url: spiritFhirExtensions.reviewedByName,
            valueString: user?.name
        });

        extensions = extensions.filter(x => x.url !== spiritFhirExtensions.reviewedById);

        extensions.push({
            url: spiritFhirExtensions.reviewedById,
            valueString: user?.sub
        });
        
        questionnaireResponse._id = undefined;
        questionnaireResponse.extension = extensions;

        return questionnaireResponse;
    }

    const patientId = getPatientId(questionnaireResponse);


    return (
        <div>
                <Grid container spacing={0} >
                    <Grid item xs={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<ArrowBackIcon />}
                        className='back-button'
                        onClick={back}
                        style={{ 
                            width: '100%', 
                            backgroundColor: 'transparent', 
                            border: 'none', 
                            boxShadow: 'none', 
                            color: 'inherit', 
                            padding: 0,
                            justifyContent: 'flex-start',
                            alignItems: 'center'
                          }}
                        >
                        Back
                        </Button>
                    </Grid>
                </Grid>
                
                <Grid container spacing={2} className='QuestionnaireHeading'>
                    <Grid item xs={12}>
                        <h3>{getQuestionnaireTitle(questionnaireResponse)}</h3>
                    </Grid>
                    <Grid item xs={2}>
                        <span className='title'>{t('questionnaireResponse.concern')}</span>
                        <RAGRaterChip rag={getQuestionnaireResponseRAG(questionnaireResponse)} />
                    </Grid>
                    <Grid item xs={2}>
                    <span className='title'>{t('questionnaireResponse.patient')}</span>
                    <div>
                        <Link to='/patientView' state={{ patientId: patientId }}>
                            <h5>{questionnaireResponse.subject?.display}</h5>
                        </Link>
                    </div>
                    </Grid>
                    <Grid item xs={2}>
                        <span className='title'>{t('questionnaireResponse.date-completed')}</span>
                        <div><h5>{extractCompletedDateFormatted(questionnaireResponse)}</h5></div>
                    </Grid>
                    <Grid item xs={3}>
                        <span className='title'>{t('questionnaireResponse.pathway')}</span>
                        <div><h5>{getQuestionnaireTitle(questionnaireResponse)}</h5></div>
                    </Grid>
                    <Grid item xs={3} alignItems="flex-end">
                        <span className='title'>{t('questionnaireResponse.review-status')}</span>
                        <div><h5>{extractReviewedDate(questionnaireResponse) ? extractReviewedDate(questionnaireResponse) : t('questionnaireResponse.not-reviewed')}</h5></div>
                    </Grid>

                    
                </Grid>
            <div className={styles.tasks}>
                <br /><br />

                <Grid container spacing={2}>
                    <Grid item xs={9} >
                        <Stack className="question-box">{questionnaireResponse?.item?.map((question: any) => (
                        <Item
                        key={String(question.linkId)}>
                            <QuestionnaireAnswerCard question={question} />
                        </Item>
                    ))}</Stack>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12} >
                        {extractReviewedDate(questionnaireResponse) != "" ? null : (
                            <Button size="medium" variant="contained" onClick={() => reviewComplete(questionnaireResponse)}>{t("command.mark-reviewed")}</Button>
                        )}
                    </Grid>
                </Grid>
                <br />
            </div>
        </div>

    );
}
